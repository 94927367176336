import { EDMOND, FRANCIS, INSPECTOR, MLLE_ROSE, MME_PERVENCHE, THE_OFFICE, REVEREND_OLIVE, SNAPCOM, THE_LAB, COLONEL_MOUTARDE, PRINCE_AZUR } from "./characters";
import { BOOK } from "./objects";
import { VERANDA } from "./places";
import { DINNER } from "./times";
import InspectorLevel1Recording from '../assets/audios/level1/Paul_McRae.mp3';
import EdmondLevel1Recording from '../assets/audios/level1/Edmond.mp3';
import FrancisLevel1Recording from '../assets/audios/level1/Francis.mp3';
import OliveLevel1Recording from '../assets/audios/level1/Olive.mp3';
import RoseLevel1Recording from '../assets/audios/level1/Rose.mp3';
import MoutardeLevel1Recording from '../assets/audios/level1/Moutarde.mp3';
import PervancheLevel1Recording from '../assets/audios/level1/Pervenche.mp3';
import AzurLevel1Recording from '../assets/audios/level1/Prince_Azur.mp3';
import recordingRick from '../assets/audios/music/NeverGonnaGiveYouUpLofiRemixByLemonadePsycho.mp3';

export const LEVELS_SOLUTIONS = [
    {
        killer: MME_PERVENCHE,
        place: VERANDA,
        time: DINNER,
        object: BOOK
    }
];

export const LEVELS_MAILS = [
    [
        {
            min: 0,
            seconds: 5,
            from: SNAPCOM,
            subject : 'Votre nouveau téléphonne',
            message : "Bienvenue sur votre Snaptel version 2008 !\n\nProfitez des nombreuses nouvelles technologies présente sur votre téléphone et de sa mémoire de 1 Mo. Parfait pour écouter votre morceau favori.\n\nPour revenir à l'écran d'accueil, appuyez sur la ligne en bas de votre téléphone.\n\nMerci pour votre confiance.\nSnapCom"
        },
        {
            min: 0,
            seconds: 5,
            from: SNAPCOM,
            subject: 'Résiliation d\'abonnement',
            message: 'Nous vous remercions de votre résiliation pour votre option Internet.\n\nVotre abonnement passe désormais de 53.65 CHF par mois à 65.53 CHF par mois.\n\nMerci pour votre confiance.\nSnapCom'
        },
        {
            min: 0,
            seconds: 25,
            from: INSPECTOR,
            subject: 'Appelle moi petit !',
            message: 'Petit !\nApelle moi vite au 705-542-5028 avec le téléphonne TOUT DE SUITE !'
        },
        {
            min: 0,
            seconds: 40,
            from: THE_OFFICE,
            subject: 'Prise d’enquête',
            message: 'Bonjour !\nVeuillez appeler le numéro suivant pour réunion préparatoire : \n856-569-5050\n\nCordialement,\nCommisariat de Moonwick.\n\n(Message automatique merci de ne pas répondre)'
        },
        {
            min: 4,
            seconds: 0,
            from: FRANCIS,
            subject: 'MESSAGE ENCODING ERROR',
            message: 'PLEASE UPDATE YOUR MAIL APP.\n\nMESSAGE TO HEX DECODE :\n526574726f7576657a206c6520706c616e2073757220766f747265206e61766967617465757220656e20746170616e742022506c616e73222064616e73206c6120726563686572636865'
        },
        {
            min: 5,
            seconds: 0,
            from: THE_LAB,
            subject: 'Nouvel indice',
            message: 'Bonsoir inspecteur,\nNous avons trouvé des morceaux de verre dans la salle à manger.\nNous espérons vous avoir fournis un sentiment de satisfaction avec cette\ndécouverte.\n\nSalutation,\nLe labo'
        },
        {
            min: 10,
            seconds: 0,
            from: THE_LAB,
            subject: 'Nouvel indice',
            message: 'Bonsoir inspecteur,\nNous pensons que le meurtre ne remonte pas plus tôt que midi.\nNous espérons vous avoir fournis un sentiment de satisfaction avec cette\ndécouverte.\n\nSalutation,\nLe labo'
        },
        {
            min: 15,
            seconds: 0,
            from: 'Adresse masquée',
            subject: 'Crypted message',
            message: '343236663665366136663735373232653064306163303230366332373638363537353732363532303634373532303732363537303631373332633230366132373631363932303635366537343635366536343735323036343735323036323732373536393734323037363635373237333230366336313230373336313663366336353230653032303664363136653637363537323265323034313730373036353663363537613264366436663639323037333639323037363666373537333230373336663735363836313639373436353761323036313736366636393732323036343635373332303639366536363666373332653064306133343331333432643331333433313264333433313334333132653230343636313639373436353733323036313734373436353665373436393666366532303666663932303736366637353733323037363666373537333230373437323666373537363635376132650d0a0d0a2d203372723072202d200d0a436f756368652043727970746167652032'
        },
        {
            min: 17,
            seconds: 0,
            from: THE_LAB,
            subject: 'Réitérez votre demande',
            message: 'Des fois, il est bon de recontrôler ce que l’on reçoit afin d’en percevoir le sens.\n\nSalutation,\nBen du Labo.'
        },
        {
            min: 18,
            seconds: 0,
            from: SNAPCOM,
            subject: 'Votre demande ne peut aboutir',
            message: 'Bonjour,\nSuite à votre demande de changement de numéro vers le 268-722-5300 ne peut aboutir car il est déjà attribué.\n\nMeilleures salutations,\nSnapcom'
        },
        {
            min: 20,
            seconds: 0,
            from: COLONEL_MOUTARDE,
            subject: 'Francis',
            message: 'F\nR\nA\nN\nC\nI\nS'
        },
        {
            min: 20,
            seconds: 30,
            from: THE_LAB,
            subject: 'Colonel Moutarde',
            message: "Inspecteur, nous venons d’avoir des nouvelles de Dr Sheperd, psychologue renommé qui suit le Colonel Moutarde. Ce dernier s’est enfuit de l’hospice où il est interné afin de se rendre dans le manoir où il avait l’habitude de passer des week-end avant son AVC qui lui a grillé le cerveau.\nSuite à son évaluation psychologique effectué par le Docteur, il a été défini qu’il ne dit rien de vrai et souffre de psychose et d’hallucinations visuelles et auditives.\n\nJ’espère que cela pourra vous aider à résoudre cette enquête une bonne fois pour toute, un bon tas de dossiers vous attend déjà sur votre bureau depuis votre absence.\n\nSalutations,\nLe Labo"
        }
    ]
];

export const LEVELS_PHONES = [
    [
        {
            phone_number: '7055425028',
            phone_holder: INSPECTOR,
            recording: new Audio(InspectorLevel1Recording)
        },
        {
            phone_number: '8565695050',
            phone_holder: EDMOND,
            recording: new Audio(EdmondLevel1Recording)
        },
        {
            phone_number: '7859665456',
            phone_holder: FRANCIS,
            recording: new Audio(FrancisLevel1Recording)
        },
        {
            phone_number: '4592950430',
            phone_holder: REVEREND_OLIVE,
            recording: new Audio(OliveLevel1Recording)
        },
        {
            phone_number: '1000000001',
            phone_holder: MLLE_ROSE,
            recording: new Audio(RoseLevel1Recording)
        },
        {
            phone_number: '4141414141',
            phone_holder: COLONEL_MOUTARDE,
            recording: new Audio(MoutardeLevel1Recording)
        },
        {
            phone_number: '2687225300',
            phone_holder: MME_PERVENCHE,
            recording: new Audio(PervancheLevel1Recording)
        },
        {
            phone_number: '7746232987',
            phone_holder: PRINCE_AZUR,
            recording: new Audio(AzurLevel1Recording)
        },
        {
            phone_number: '6666666666',
            phone_holder: 'Rick',
            recording: new Audio(recordingRick)
        }
    ]
];

export const SEARCH_APP = [
    {
        query: 'Plans',
        content: 'Version 1000000001\n\nListe des pièces :\n1 : Bureau\n2: Hall\n3: Salon\n4: Bibliothèque\n5: Salle des preuves\n6: Salle à manger\n7: Salle de billard\n8: Véranda\n9: Salle de bal\n0: Cuisine\n\n Plan :\n        N\n         |\nW -- O -- E\n         |\n        S\n\n_____________\n| 1 |---| 2 |---| 3 |\n   I        I        I\n| 4 |---| 5 |---| 6 |\n   I     | 5 |    | 6 |\n| 7 |---| 5 |   | 6 |\n   I        I        I\n| 8 |---| 9 |---| 0 |\n----------------------\n\nNotes : \n\n- La roseraie se trouve au sud. On y accède depuis la véranda ou depuis la fontaine\n- La fontaine se trouve à l\'est du batiment. On y accède depuis la salle à manger ou depuis la roseraie.',
    },
    {
        query: 'Chamonix',
        content : "Chamonix-Mont-Blanc (ou plus communément Chamonix) est une station de villégiature à la jonction de la France, de la Suisse et de l'Italie.\n\nSituée au pied du Mont-Blanc, le plus haut sommet des Alpes, elle est réputée pour ses pistes de ski. Toute l'année, les téléphériques transportent les visiteurs vers les différents sommets environnants qui offrent des vues panoramiques exceptionnelles, comme l'Aiguille du Midi au-dessus de la ville et la Pointe Helbronner au-dessus des glaciers à la frontière italienne.\n\nCoordonées :\nN°45.9295043\nE°6.7890125"
    }
]