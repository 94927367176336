import React, { useEffect, useState } from 'react';
import Music from '../../../../assets/audios/music/NeverGonnaGiveYouUpLofiRemixByLemonadePsycho.mp3';
import AppHeader from '../../../../components/appHeader';
import KeypadButton from '../../../../components/keypadButton';
import PlayIcon from '../../../../assets/images/play-icon.png';
import PauseIcon from '../../../../assets/images/pause-icon.png';
import MusicImage from '../../../../assets/images/nggyu.png';
import PrevIcon from '../../../../assets/images/icon-prev.png';
import NextIcon from '../../../../assets/images/icon-next.png';

const MusicPlayer = () => {

    const [isPlaying, setIsPlaying] = useState(false);
    const [audio] = useState(new Audio(Music));

    useEffect(() => {
        //Add event listner
        audio.addEventListener('ended', () => {
            setIsPlaying(false);
        });

        //Stop player when component is destroyed
        return (() => {
            audio.pause();
            audio.removeEventListener('ended', () => {
                setIsPlaying(false);
            });
        });
    }, []);

    return (
        <div className="phone--music-player">
            <AppHeader title="Musique" />
            <section className="music-player--main">
                <div className="music-player--current">
                    <img className="music-thumbnail" src={MusicImage} />
                    <h4 className="music-title">Never Gonna Give You Up (Lofi version)</h4>
                    <p className="music-artist">Lemonade Psycho</p>
                </div>
                <div className="music-player--controller">
                <img src={PrevIcon} className="controller-icon" />
                    <KeypadButton color="purple" icon={isPlaying ? PauseIcon : PlayIcon } action={() => {
                    if (isPlaying === true) {
                        setIsPlaying(false);
                        audio.pause();
                    } else {
                        setIsPlaying(true);
                        audio.play();
                    }
                }} />
                <img src={NextIcon} className="controller-icon" />
                </div>
            </section>
        </div>
    );
}

export default MusicPlayer;