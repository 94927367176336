import { React } from 'react';
import { useGame } from '../contexts/gameContext';

const NotePad = () => {

    const { gameState } = useGame();

    return (
        <section className="note-pad">
            <h1 className="note-pad--title">Bloc note</h1>
            <textarea className="note-pad--text" defaultValue={ gameState.currentLevel === 0 && 'Bonsoir inspecteur !\n\nBienvenue dans votre première enquête.\n\nIci, vous pourrez prendre des notes durant votre enquête.\n\nPrenez garde à ne pas recharger votre navigateur !\n\nPour commencer ouvrez vos mails en cliquant sur l\'icone.\n\nP.S : Pour en sortir utilisez la barre en bas de l\'écran du téléphonne.' }></textarea>
        </section>
    )
}

export default NotePad;