export const DAWN = 'Aube';
export const BREAKFAST = 'Petit déjeuner';
export const MORRNNG_END = 'Fin de matinée';
export const LUNCH = 'Déjeuné';
export const EARLY_AFTERNOON = 'Début d\'après-midi';
export const TEA_TIME = 'Heure du thé';
export const DUSK = 'Crépuscule';
export const DINNER = 'Dîner';
export const NIGHT = 'Nuit';
export const MIDNIGHT = 'Minuit';

export const times = [
    DAWN,
    BREAKFAST,
    MORRNNG_END,
    LUNCH,
    EARLY_AFTERNOON,
    TEA_TIME,
    DUSK,
    DINNER,
    NIGHT,
    MIDNIGHT
]