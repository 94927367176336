export const COLONEL_MOUTARDE = 'Colonel Moutarde';
export const REVEREND_OLIVE = 'Révérend Olive';
export const PROFESSEUR_VIOLET = 'Professeur Violet';
export const PRINCE_AZUR = 'Prince Azur';
export const FRANCIS = 'Francis';
export const MME_LEBLANC = 'Mme Leblanc';
export const MLLE_ROSE = 'Mlle Rose';
export const MME_PERVENCHE = 'Mme Pervenche';
export const MME_BEAUMONT = 'Mme Beaumont';
export const LADY_LAVANDE = 'Lady Lavande';
export const INSPECTOR = 'Paul McRae';
export const EDMOND = 'Edmond';
export const SNAPCOM = 'SnapCom';
export const THE_OFFICE = 'Le Bureau';
export const THE_LAB = 'Le labo';

export const characters = [
    COLONEL_MOUTARDE,
    REVEREND_OLIVE,
    PROFESSEUR_VIOLET,
    PRINCE_AZUR,
    FRANCIS,
    MME_LEBLANC,
    MLLE_ROSE,
    MME_PERVENCHE,
    MME_BEAUMONT,
    LADY_LAVANDE
];