import React from 'react';

const MainScreen = ({ children }) => {
    return (
        <div className="phone--main-screen">
            {children}
        </div>
    );
}

export default MainScreen;