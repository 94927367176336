import React, { useEffect, useState } from 'react';
import { LEVELS_PHONES } from '../../../../tools/gameSettings';
import EndCallIcon from '../../../../assets/images/end-call-icon.png';
import KeypadButton from '../../../../components/keypadButton';
import { useGame } from '../../../../contexts/gameContext';
import NotFound from '../../../../assets/audios/occupe.mp3';
import Tone from '../../../../assets/audios/tonalite.mp3';
import { sleep } from '../../../../tools/functions';

const CallScreen = ({ phoneNumber, endCallAction }) => {

    const [caller, setCaller] = useState(null);
    const [endSearch, setEndSearch] = useState(false);
    const [call, setCall] = useState(null);
    const { gameState } = useGame();

    //Search phone number in gameSettings when component is started
    useEffect(() => {
        //Start with dialing tone
        setCall(new Audio(Tone));
        sleep(2000).then(() => {
            //Search recording
            let found = false;
            for (let i = 0; i < LEVELS_PHONES[gameState.currentLevel].length; i++) {
                const element = LEVELS_PHONES[gameState.currentLevel][i];
                if (element.phone_number === phoneNumber) {
                    //Phone number is founded play the recording
                    found = true;
                    setCaller(element);
                    setCall(element.recording);
                    i = LEVELS_PHONES[gameState.currentLevel].length;
                }
            }
            if (!found) {
                setCall(new Audio(NotFound));
            }
            //Confirm search is over
            setEndSearch(true);
        });
    }, []);

    useEffect(() => {
        //Play recording when player isn't null
        if (call !== null) {
            call.addEventListener('ended', endCallAction);
            call.play();
        }

        //Stop playing if component is destroyed
        return (() => {
            if (call !== null) {
                call.removeEventListener('ended', endCallAction);
                call.pause();
            }
        });
    }, [call]);

    return (
        <div className="phone--call-screen">
            <div className="phone-call--head">
                <p className="phone-call--number">{caller === null ? phoneNumber : caller.phone_holder}</p>
                <p className="phone-call--details">{caller === null ? endSearch === false ? 'Appel en cours' : 'Numéro inconnu' : 'Connecté'}</p>
            </div>
            <KeypadButton color="red" icon={EndCallIcon} action={() => {
                if (call !== null) {
                    call.pause();
                }
                endCallAction();
            }} />
        </div>
    );
}

export default CallScreen;