import React, { useEffect, useReducer, useRef } from "react";
import NotePad from "../components/notePad";
import Phone from "../components/phone";

const Game = () => {

    /**
     * Function used for sending the hints
     */
    const timerLogic = (state, action) => {
        switch (action.type) {
            case INCRASE_TIMER: {
                if (state.s + 1 >= 60) {
                    return { s: 0, m: state.m + 1 };
                } else {
                    return { s: state.s + 1, m: state.m };
                }
            }
            case RESET_TIMER: {
                return { s: 0, m: 0 };
            }
            default: {
                throw new Error("Bad operation for timer");
            }
        }
    };

    const INCRASE_TIMER = "INCRASE";
    const RESET_TIMER = "RESET";
    const timer = useRef();
    const [time, setTime] = useReducer(timerLogic, { s: 0, m: 0 });

    useEffect(() => {
        //Start the timer
        timer.current = setInterval(() => {
            setTime({ type: INCRASE_TIMER });
        }, 1000);

        //Kill timer when component is destroyed
        return (() => {
            clearInterval(timer.current);
        });
    }, []);

    return (
        <section className="page page--game-board">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-5">
                        <Phone time={time} />
                    </div>
                    <div className="col-xs-12 col-md-7">
                        <NotePad />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Game;
