import React, { useRef, useState } from 'react';
import { SEARCH_APP } from '../../../../tools/gameSettings';
import SearchIcon from '../../../../assets/images/search.png';

const WebScreen = () => {

    const queryRef = useRef('');
    const [result, setResult] = useState(null);

    const search = (query) => {
        let founded = null;
        for(let i = 0; i < SEARCH_APP.length; i++) {
            if(SEARCH_APP[i].query.toLowerCase() === query.toLowerCase()) {
                founded = SEARCH_APP[i];
                i = SEARCH_APP.length;
            }
        }
        return founded;
    };

    const handleSubmit = (event)=>{
        event.preventDefault();
        setResult(search(queryRef.current.value));
    };

    return (
        <div className="phone--web" style={{width:'100%',height:'100%'}}>
            <section className="app-header">
                <h2 className="app-header--title">WEB (Mode hors ligne)</h2>
            </section>
            <section className="web--navigator">
                <form onSubmit={handleSubmit} className="navigator--search-bar">
                    <label htmlFor="query">Que recherchez vous ?</label>
                    <input id="query" type="text" ref={queryRef} />
                    <div className="search-bar--submit">
                        <input type="submit" />
                        <img src={SearchIcon} />
                    </div>
                </form>
                {
                    result && (
                        <div className="navigator--result">
                            <h3 className="result--title">{result.query}</h3>
                            <p className="result--content">{result.content}</p>
                        </div>
                    )
                }
            </section>
        </div>
    );
}

export default WebScreen;