import React, { useState, useEffect } from 'react';
import PencilIcon from '../../../../assets/images/pencil-icon.png';
import AppHeader from '../../../../components/appHeader';
import MailItem from '../../../../components/mailItem';
import { useGame } from '../../../../contexts/gameContext';
import { LEVELS_MAILS } from '../../../../tools/gameSettings';

const MailScreen = ({time = {'s': 0, 'm': 0}}) => {

    const [currentMail, setCurrentMail] = useState(null);
    const [inbox, setInbox] = useState([]);
    const { gameState } = useGame(); 
    const currentLevel = gameState.currentLevel;

    // Function used to refresh the mail list
    const refreshMail = () => {
        let newList = [];
        let mailsList = LEVELS_MAILS[currentLevel];

        for(let i = 0; i < mailsList.length; i++) {
            const mail = mailsList[i];
            if(mail.min < time.m) {
                newList.push(mail);
            } else if (mail.min === time.m && mail.seconds <= time.s ) {
                newList.push(mail);
            } else {
                // No more mails
                i = mailsList.length;
            }
        }

        return newList;
    }

    useEffect(() => {
        if(currentMail === null) {
            setInbox(refreshMail());
        }
    }, [time]);

    return (
        <div className="phone--mail">
            <AppHeader title={ currentMail !== null ? currentMail.subject.length > 14 ? `${currentMail.subject.substring(0,14)} ...` : currentMail.subject : 'Boîte de réception' } action={ currentMail !== null ? () => { setCurrentMail(null) } : null } />
            {
                currentMail !== null ? (
                    <section className="mail--reader">
                        <p className="sender">De { currentMail.from }</p>
                        <p className="content">{ currentMail.message }</p>
                    </section>
                ) : (
                    <section className="mail--index">
                        {
                            inbox.map((element, index) => (
                                <MailItem key={ index } sender={element.from} subject={element.subject} time={{m: element.min, s: element.seconds}} action={() => { setCurrentMail(element) }} />
                            ))
                        }
                    </section>
                )
            }
            <button className="mail--write">
                <img src={ PencilIcon } />
            </button>
        </div>
    );
}

export default MailScreen;