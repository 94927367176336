export const HALL = 'Hall';
export const SALON = 'Salon';
export const DINING_ROOM = 'Salle à manger';
export const KITCHEN = 'Cuisine';
export const BALLROOM = 'Salle de bal';
export const VERANDA = 'Véranda';
export const BILLIARD_ROOM = 'Salle de billard';
export const LIBRARY = 'Bibliothèque';
export const OFFICE = 'Bureau';
export const ROSE_GARDEN = 'Roseraie';
export const FOUNTAIN = 'Fontaine';

export const places = [
    HALL,
    SALON,
    DINING_ROOM,
    KITCHEN,
    BALLROOM,
    VERANDA,
    BILLIARD_ROOM,
    LIBRARY,
    OFFICE,
    ROSE_GARDEN,
    FOUNTAIN
];