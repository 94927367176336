import { React } from 'react';
import Arrow from '../assets/images/arrow-left.png';

const AppHeader = ({title = '', action = null}) => {
    return (
        <section className="app-header">
            {
                action !== null && (
                    <button onClick={action} className="app-header--action">
                        <img src={Arrow} />
                    </button>
                )
            }
            <h2 className="app-header--title">{title}</h2>
        </section>
    );
}

export default AppHeader;