import React, { useState } from "react";
import KeypadButton from "../../../../components/keypadButton";
import CallScreen from "./callScreen";
import CallIcon from "../../../../assets/images/call-icon.png";
import Phone0 from '../../../../assets/audios/Phone0.mp3';
import Phone1 from '../../../../assets/audios/Phone1.mp3';
import Phone2 from '../../../../assets/audios/Phone2.mp3';
import Phone3 from '../../../../assets/audios/Phone3.mp3';
import Phone4 from '../../../../assets/audios/Phone4.mp3';
import Phone5 from '../../../../assets/audios/Phone5.mp3';
import Phone6 from '../../../../assets/audios/Phone6.mp3';
import Phone7 from '../../../../assets/audios/Phone7.mp3';
import Phone8 from '../../../../assets/audios/Phone8.mp3';
import Phone9 from '../../../../assets/audios/Phone9.mp3';
import PhoneAsterisk from '../../../../assets/audios/PhoneAsterisk.mp3';
import PhoneHash from '../../../../assets/audios/PhoneHash.mp3';

const KeypadScreen = () => {
    const [number, setNumber] = useState("");
    const [isCalling, setIsCalling] = useState(false);

    const addNumber = (keypadNumber,sound) => {
        if (number.length < 10) {
            new Audio(sound).play();
            setNumber(`${number}${keypadNumber}`);
        }
    };

    const removeNumber = () => {
        if (number.length > 0) {
            setNumber(number.slice(0, -1));
        }
    };

    return (
        <div className="phone--keypad">
            {isCalling === false ? (
                <>
                    <div className="phone-keypad--head">
                        <p className="phone-keypad--number">{number}</p>
                        {number.length > 0 && (
                            <button onClick={() => removeNumber()}>Effacer</button>
                        )}
                    </div>

                    <div className="phone-keypad--grid">
                        <KeypadButton title="1" text="" action={() => addNumber("1", Phone1)} />
                        <KeypadButton title="2" text="abc" action={() => addNumber("2", Phone2)} />
                        <KeypadButton title="3" text="def" action={() => addNumber("3", Phone3)} />
                        <KeypadButton title="4" text="ghi" action={() => addNumber("4", Phone4)} />
                        <KeypadButton title="5" text="jkl" action={() => addNumber("5", Phone5)} />
                        <KeypadButton title="6" text="mno" action={() => addNumber("6", Phone6)} />
                        <KeypadButton title="7" text="pqrs" action={() => addNumber("7", Phone7)} />
                        <KeypadButton title="8" text="tuv" action={() => addNumber("8", Phone8)} />
                        <KeypadButton title="9" text="wxyz" action={() => addNumber("9", Phone9)} />
                        <KeypadButton title="*" text="" action={() => addNumber("*", PhoneAsterisk)} />
                        <KeypadButton title="0" text="+" action={() => addNumber("0", Phone0)} />
                        <KeypadButton title="#" text="" action={() => addNumber("#", PhoneHash)} />
                    </div>
                    <KeypadButton color="green" icon={CallIcon} action={() => {
                        if (number.length > 0) {
                            setIsCalling(true)
                        }
                    }} />
                </>
            ) : (
                <>
                    <CallScreen phoneNumber={number} endCallAction={() => { setIsCalling(false); }} />
                </>
            )}
        </div>
    );
};

export default KeypadScreen;
