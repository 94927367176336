import React, { useState } from 'react';
import DavinciScreen from '../views/phone/app/davinci/davinciScreen';
import KeypadScreen from '../views/phone/app/phone/keypadScreen';
import MainScreen from '../views/phone/mainScreen';
import MusicPlayer from '../views/phone/app/music/musicPlayer';
import WebScreen from '../views/phone/app/web/webScreen';
import Report from '../views/phone/app/report/report';
import PhoneIcon from '../assets/images/phone-app-icon.png';
import MailIcon from '../assets/images/mail-app-icon.png';
import MusicIcon from '../assets/images/music-app-icon.png';
import WebIcon from '../assets/images/web-app-icon.png';
import DavinciIcon from '../assets/images/davinci-app-icon.png';
import ReportIcon from '../assets/images/report-app-icon.png';
import PhoneAppButton from './phoneAppButton';
import MailScreen from '../views/phone/app/mail/mailScreen';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Notification from './notification';

const Phone = (({ time = { 'm': 0, 's': 0 } }) => {

    const [currentView, setCurrentView] = useState('main');

    return (
        <aside className="phone">
            <div className="phone--frame">
                <span className="phone-frame--line" />
                <span className="phone-frame--camera" />
            </div>
            <div className="phone--screen">
                <Notification time={time} />
                <div className="phone-screen--header">
                    <p className="phone--carrier">SnapCom</p>
                    <p className="phone--time">{time.m >= 10 ? time.m : `0${time.m}`}:{time.s >= 10 ? time.s : `0${time.s}`}</p>
                </div>
                <MainScreen>
                    <PhoneAppButton title="Téléphone" icon={PhoneIcon} action={() => { setCurrentView('keypad') }} />
                    <PhoneAppButton title="Mail" icon={MailIcon} action={() => { setCurrentView('mail') }} />
                    <PhoneAppButton title="Musique" icon={MusicIcon} action={() => { setCurrentView('music') }} />
                    <PhoneAppButton title="Web" icon={WebIcon} action={() => { setCurrentView('web') }} />
                    <PhoneAppButton title="DaVici" icon={DavinciIcon} action={() => { setCurrentView('davinci') }} />
                    <PhoneAppButton title="Rapport" icon={ReportIcon} action={() => { setCurrentView('report') }} />
                </MainScreen>
                <TransitionGroup className="phone--transition">
                    <CSSTransition
                        key={
                            currentView
                        }
                        classNames="app"
                        timeout={{
                            appear: 0,
                            enter: 250,
                            exit: 250
                        }}>
                        {
                            currentView === 'music' ? (
                                <MusicPlayer />
                            ) : currentView === 'keypad' ? (

                                <KeypadScreen />
                            ) : currentView === 'web' ? (
                                <WebScreen />
                            ) : currentView === 'davinci' ? (
                                <DavinciScreen />
                            ) : currentView === 'report' ? (
                                <Report time={time} />
                            ) : currentView === 'mail' ? (
                                <MailScreen time={time} />
                            ) : (
                                <></>
                            )
                        }
                    </CSSTransition>
                </TransitionGroup>
            </div>
            <div className="phone--frame">
                <button className="phone-frame--line" onClick={() => { setCurrentView('main') }}>Home</button>
            </div>
        </aside>
    )
});

export default Phone;