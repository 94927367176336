import React from 'react';
import { GameProvider } from './contexts/gameContext';
import './scss/app.scss';
import SnapClue from './tools/snapClue';

function App() {

  return (
    <div className="App">
      <GameProvider>
        <SnapClue />
      </GameProvider>
    </div>
  );
}

export default App;
