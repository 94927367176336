import { React } from 'react';

const MailItem = ({sender = '', subject = '', time = {m: 0, s: 0}, action = null}) => {
    return (
        <button className="mail-item" onClick={action}>
            <h3 className="mail-item--title">{ sender }</h3>
            <p className="mail-item--subject">{ subject }</p>
            <p className="mail-item--time">{ time.m < 10 ? `0${time.m}` : time.m }:{ time.s < 10 ? `0${time.s}` : time.s }</p>
        </button>
    );
}

export default MailItem