import React, { useEffect, useState } from 'react';
import { START_GAME, useGame } from '../contexts/gameContext';
import { LEVELS_SOLUTIONS } from '../tools/gameSettings';
import Logo from '../assets/images/SnapClue-logo.png';

const Start = () => {

    const { gameDispatcher } = useGame();
    const [currentLevel, setCurrentLevel] = useState(0);
    

    useEffect(() => {
        let doneLevel = localStorage.getItem('level');
        if(doneLevel === null) {
            setCurrentLevel(0);
        } else {
            setCurrentLevel(parseInt(doneLevel) + 1);
        }
    }, []);

    return (
        <section className="game-start">
            <h1 className="game--title"><img src={Logo} /></h1>
            <p className="game--level">Niveau {currentLevel + 1}</p>
            {
                LEVELS_SOLUTIONS[currentLevel] ? (
                    <button className="btn btn--green" onClick={() => {
                        gameDispatcher({ type: START_GAME, level: currentLevel });
                    }}>Commencer</button>
                ) : (
                    <p className="game--level">Bientôt disponible</p>
                )
            }
            {
                currentLevel > 0 && (
                    <button className="btn btn--red" onClick={() => {
                        localStorage.clear();
                        setCurrentLevel(0);
                    }}>Réinitialiser</button>
                )
            }
            
            <p className="game--screen-notice">Votre écran est trop petit pour SnapClue.<br />Merci d'ouvrir ce jeu sur un ordinateur.</p>
        </section>
    );
};

export default Start;