import { React, useEffect, useState } from 'react';
import { useGame } from '../contexts/gameContext';
import MAIL_ICON from '../assets/images/mail-app-icon.png';
import Ding from '../assets/audios/ding.mp3';
import { LEVELS_MAILS } from '../tools/gameSettings';
import { sleep } from '../tools/functions';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

export const Notification = ({ time = { 's': 0, 'm': 0 } }) => {

    const { gameState } = useGame();
    const [notificationContent, setNotifocationContent] = useState(null);

    useEffect(() => {

        if (notificationContent === null) {
            let mailsList = LEVELS_MAILS[gameState.currentLevel];

            if (mailsList) {
                for (let i = 0; i < mailsList.length; i++) {
                    const mail = mailsList[i];
                    if (mail.min === time.m && mail.seconds === time.s) {
                        setNotifocationContent(`${mail.from} vous à envoyé un message.`);
                        i = mailsList.length;
                    }
                }
            }
        }
    }, [time]);

    useEffect(() => {
        if (notificationContent !== null) {
            //Play sound and hide it after 3 seconds
            new Audio(Ding).play();
            sleep(3000).then(() => {
                setNotifocationContent(null);
            });
        }
    }, [notificationContent]);

    return (
        <TransitionGroup className="phone--notification">
            <CSSTransition
                key={notificationContent}
                classNames="notification-anim"
                timeout={{
                    appear: 0,
                    enter: 250,
                    exit: 250
                }}>
                {
                    notificationContent ? (
                        <div className="notification--body">
                            <div className="notification--row">
                                <div className="col-xs-3">
                                    <img className="notification--icon" src={MAIL_ICON} />
                                </div>
                                <div className="col-xs-9">
                                    <h3 className="notification--title">Mail</h3>
                                    <p className="notification--text">{notificationContent}</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )
                }
            </CSSTransition>
        </TransitionGroup>
    );
}

export default Notification;