import React, { useRef, useState, useEffect } from 'react';
import { characters } from '../../../../tools/characters';
import { LEVELS_SOLUTIONS } from '../../../../tools/gameSettings';
import { objects } from '../../../../tools/objects';
import { places } from '../../../../tools/places';
import { times } from '../../../../tools/times';
import AppHeader from '../../../../components/appHeader';
import BoxOpen from '../../../../assets/images/box-open.png';
import BoxCheck from '../../../../assets/images/box-check.png';
import { END_GAME, useGame } from '../../../../contexts/gameContext';

const Report = ({ time }) => {

    const characterRef = useRef('');
    const timeRef = useRef('');
    const whereRef = useRef('');
    const howRef = useRef('');
    const [goodResponse, setGoodResponse] = useState(null);
    const [finalTime, setFinalTime] = useState({ m: 0, s: 0 });
    const { gameDispatcher, gameState } = useGame();

    const submitHandle = (e) => {
        e.preventDefault();
        const character = characterRef.current.value;
        const time = timeRef.current.value;
        const where = whereRef.current.value;
        const how = howRef.current.value;

        if (character !== '' && time !== '' && where !== '' && how !== '') {
            setGoodResponse(validation(character, time, where, how));
        } else {
            console.log('Missing values');
        }
    };

    const validation = (character, time, where, how) => {
        let counter = 0;
        const game = LEVELS_SOLUTIONS[gameState.currentLevel];

        //Check characters
        if (character === game.killer) {
            counter += 1;
        }

        //Check time
        if (time === game.time) {
            counter += 1;
        }

        //Check place
        if (where === game.place) {
            counter += 1;
        }

        //Check object
        if (how === game.object) {
            counter += 1;
        }

        return counter;
    };

    useEffect(() => {
        if (goodResponse !== null) {
            if (goodResponse < 4) {
                console.log(`Player has not found the solution. ${goodResponse}/4`);
            } else {
                console.log('Player win');
                setFinalTime({ m: time.m, s: time.s });
            }
        } else {
            console.log('No change');
        }
    }, [goodResponse]);

    return (
        <div className="phone--report">
            <AppHeader title="Rapport" />
            {
                goodResponse === null ? (
                    <section className="main report--form">
                        <form onSubmit={submitHandle}>
                            <div className="report--form-line">
                                <label>Qui :</label>
                                <select ref={characterRef}>
                                    <option value="">Sélectionnez un suspect</option>
                                    {
                                        characters.map((element, index) => (
                                            <option key={index} id={element} value={element}>{element}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="report--form-line">
                                <label>Quand :</label>
                                <select ref={timeRef}>
                                    <option value="">Sélectionnez une heure</option>
                                    {
                                        times.map((element, index) => (
                                            <option key={index} id={element} value={element}>{element}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="report--form-line">
                                <label>Ou :</label>
                                <select ref={whereRef}>
                                    <option value="">Sélectionnez un lieux</option>
                                    {
                                        places.map((element, index) => (
                                            <option key={index} id={element} value={element}>{element}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="report--form-line">
                                <label>Comment :</label>
                                <select ref={howRef}>
                                    <option value="">Sélectionnez un objet</option>
                                    {
                                        objects.map((element, index) => (
                                            <option key={index} id={element} value={element}>{element}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <input type="submit" className="btn btn--red" value="Soumettre le rapport" />
                        </form>
                    </section>
                ) : goodResponse < 4 ? (
                    <section className="report--wrong main">
                        <img className="report--status" src={BoxOpen} />
                        <h2 className="report--title">Vous n'avez pas encore résolu cette affaire</h2>
                        <p className="report--text">Pour le moment vous avez donné {goodResponse > 1 ? `${goodResponse} bonnes réponses` : `${goodResponse} bonne réponse`}.</p>
                        <button className="btn btn--red" onClick={() => { setGoodResponse(null) }}>Retour</button>
                    </section>
                ) : (
                    <section className="report--win main">
                        <img className="report--status" src={BoxCheck} />
                        <h2 className="report--title">Félicitations !</h2>
                        <p className="report--text">Vous avez résolu cette affaire avec succès en {finalTime.m > 1 ? `${finalTime.m} minutes` : `${finalTime.m} minute`} et {finalTime.s > 1 ? `${finalTime.s} secondes` : `${finalTime.s} seconde`}.</p>
                        <button className="btn btn--green" onClick={() => {
                            localStorage.setItem('level', gameState.currentLevel);
                            gameDispatcher({ type: END_GAME });
                        }}>Fin du jeu</button>
                    </section>
                )
            }
        </div>
    );
}

export default Report;