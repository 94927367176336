import { React } from 'react';

const PhoneAppButton = ({ title = '', icon = null, action = null }) => {
    return (
        <button className="phone-app" onClick={action}>
            <img className="app-icon" src={icon} />
            <span className="app-title">{ title }</span>
        </button>
    );
}

export default PhoneAppButton;