export const TELESCOPE = 'Longue-vue';
export const REVOLVER = 'Revolver';
export const BOOK = 'Livre rare';
export const WALLET = 'Portefeuille';
export const PEN = 'Stylo en or';
export const LETTER_OPENER = 'Ouvre-lettres';
export const CLIPBOARD = 'Presse-papier';
export const MEDAL = 'Médaille';
export const POCKET_WATCH = 'Montre à gousset';
export const HAIR_PIN = 'Épingle à cheveux';
export const BROOCH = 'Broche';

export const objects = [
    TELESCOPE,
    REVOLVER,
    BOOK,
    WALLET,
    PEN,
    LETTER_OPENER,
    CLIPBOARD,
    MEDAL,
    POCKET_WATCH,
    HAIR_PIN,
    BROOCH
];

export const antiquity_objects = [
    TELESCOPE,
    REVOLVER,
    BOOK
];

export const office_accessories_objects = [
    WALLET,
    PEN,
    LETTER_OPENER,
    CLIPBOARD
];

export const jewelry_objects = [
    MEDAL,
    POCKET_WATCH,
    HAIR_PIN,
    BROOCH
];