import { React } from 'react';

const KeypadButton = ({ color = null, icon = null, title = "", text = null, action=null }) => {
    return (
        <button className={`phone-app-button${color !== null ? ` phone-app-button--${color}` : ''}`} onClick={action}>
            <div className="phone-app-button--content">
                {
                    icon ? (
                        <img src={icon} className="phone-app-button--icon" />
                    ) : (
                        <>
                            <p className="phone-app-button--title">{title}</p>
                            {
                                text !== null && (
                                    <p className="phone-app-button--small">{text}</p>
                                )
                            }
                        </>
                    )
                }
            </div>
        </button>
    )
}

export default KeypadButton;