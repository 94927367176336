import React, { useEffect } from 'react';
import { RESTORE_CONTEXT, useGame } from '../contexts/gameContext';
import Game from '../views/game';
import Start from '../views/start';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const SnapClue = () => {

    const { gameState, gameDispatcher } = useGame();

    useEffect(() => {
        gameDispatcher({ type: RESTORE_CONTEXT });
    }, []);

    return (
        <TransitionGroup>
            <CSSTransition
                key={gameState.currentLevel}
                classNames="game"
                timeout={{
                    appear: 0,
                    enter: 700,
                    exit: 700
                }}>
                {
                    gameState.currentLevel !== null ? (
                        <Game />
                    ) : (
                        <Start />
                    )
                }
            </CSSTransition>
        </TransitionGroup>
    );
}

export default SnapClue;