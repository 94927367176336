import React, { useContext, useReducer } from 'react';

const GameContext = React.createContext();
export const RESTORE_CONTEXT = 'RESTORE_CONTEXT';
export const START_GAME = 'START_GAME';
export const END_GAME = 'END_GAME';

const gameReducer = (state, action) => {
    switch(action.type) {
        case RESTORE_CONTEXT: {
            return {
                ...state,
                currentLevel: null,
                isLoading: false
            };
        }
        case START_GAME: {
            return {
                ...state,
                currentLevel: action.level,
            };
        }
        case END_GAME: {
            return {
                ...state,
                currentLevel: null
            };
        }
        default : {
            throw new Error('Non valid action');
        }
    }
};

export const GameProvider = ({ children }) => {
    const [gameState, gameDispatcher] = useReducer(gameReducer, {
        isLoading: true,
        currentLevel: null
    });

    const value = { gameState, gameDispatcher };

    return (<GameContext.Provider value={ value }>{ children }</GameContext.Provider>)
}

export const useGame = () => {
    const context = useContext(GameContext);

    if(context === undefined) {
        throw new Error('Unable to find context');
    }

    return context;
}