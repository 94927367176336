import React, { useRef, useState } from 'react';
import AppHeader from '../../../../components/appHeader';
import HalfDecode from '../../../../assets/images/half-decode-icon.png';

const DavinciScreen = () => {

    const decodeRef = useRef('');
    const [result,setResult] = useState('');

    const hex_to_ascii = (str1) => {
        var hex = str1.toString();
        var str = '';
        for (var n = 0; n < hex.length; n += 2) {
            str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        }
        return str;
    };

    const submitHandle = (e) => {
        e.preventDefault();
        setResult(hex_to_ascii(decodeRef.current.value));
    }

    return (
        <div className="phone--davinci">
            <AppHeader title="DaVici (Free version)" />
            <section className="davinci--main">
                <h2 className="davinci--title">Encoded</h2>
                <textarea ref={decodeRef} onChange={submitHandle} />
                <img className="davinci--icon" src={HalfDecode} />
                <h2 className="davinci--title">Résultat</h2>
                <div className="davinci--result">{result}</div>
            </section>
        </div>
    );
}

export default DavinciScreen;